import React from "react"
import DynamicComponent from "../components/DynamicComponent"
import Layout from "../components/layout"
import SEO from "../components/seo"
import StoryblokService from '../utils/storyblok-service'

export default class extends React.Component {
  state = {
    story: {
      content: this.props.pageContext.story ? JSON.parse(this.props.pageContext.story.content) : {}
    }
  }

  async getInitialStory() {
    let { data: { story } } = await StoryblokService.get(`cdn/stories/${this.props.pageContext.story.full_slug}`, {'resolve_links': 'url'})
    return story
  }

  async getRelatedProducts() {
    let cat = this.props.pageContext.story.full_slug
    cat = cat.substr(0, cat.indexOf('/'));

    let {
      data: { stories },
    } = await StoryblokService.get(
      `cdn/stories/`, {
        "is_startpage": 0,
        "excluding_slugs": this.props.pageContext.story.full_slug,
        "starts_with": cat
      },
      { resolve_links: "url" }
    )
    return stories
  }

  async componentDidMount() {
    let story = await this.getInitialStory()
    let related_products = await this.getRelatedProducts()
    
    if(story.content) this.setState({ story, related_products })
    setTimeout(() => StoryblokService.initEditor(this), 200)
  }

  render() {
    return (
       <Layout location={this.props.location}>
        <SEO title={this.state.story.name} />
        <DynamicComponent 
          blok={this.state.story.content} 
          related_products={this.state.related_products}
          key={this.props.pageContext.story._uid} />
      </Layout>
    )
  }
}
